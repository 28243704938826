import { Routes } from '@angular/router';
import { loggedInGuard } from './permissions/logged-in.guard';

export const customerRoutes: Routes = [
	{
		path: 'digitale-visitenkarte',
		loadChildren: () => import('./digitale-visitenkarte/digitale-visitenkarte.routes').then(m => m.DIGITALE_VISITENKARTE_ROUTES),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: '',
		redirectTo: '/startoidclogin.do',
		pathMatch: 'full'
	},
];
