import { NodeFunction } from '../../permissions/models/node-function';

export const getNodes: NodeFunction = (user, feature, bouncer, and, or, not) => [
	{
		treeName: 'booking',
		name: 'bookingTree',
		url: '',
		id: 'bookingTree'
	},
	{
		name: 'help',
		id: 'help',
		icon: 'allianz_helpicon',
		parameters: {
			'showInAltNav': true
		},
		nameKey: 'subnav.help.alt',
		url: '',
		children: [
			{
				nameKey: 'subnav.help.alt',
				id: 'faq',
				url: '/workflow.do?from=menu&to=faq'
			},
			{
				nameKey: 'subnav.hotline.alt',
				id: 'hotline',
				url: '/workflow.do?from=menu&to=help/hotline',
			}
		]
	},
	{
		nameKey: 'subnav.shoppingcart.alt',
		id: 'shoppingcart',
		icon: 'allianz_shoppingcart',
		parameters: {
			'showInAltNav': true
		},
		url: '/workflow.do?from=menu&to=shoppingcart'
	},
	{
		name: 'archives',
		id: 'archives',
		icon: 'allianz_archiveicon',
		parameters: {
			'showInAltNav': true
		},
		nameKey: 'subnav.archives.alt',
		url: '',
		children: [
			{
				nameKey: 'subnav.documente.alt',
				id: 'slicelist',
				url: '/workflow.do?from=menu&to=slicelistview',
				permission: feature.has('slicelist')
			},
			{
				nameKey: 'subnav.importdocuments.alt',
				id: 'importdocuments',
				url: '/workflow.do?from=menu&to=importdocuments'
			},
			{
				nameKey: 'subnav.bestellungen.alt',
				id: 'archive',
				url: '/workflow.do?from=menu&to=archive'
			}
		]
	},
	{
		nameKey: 'subnav.preferences.masteroverview.alt',
		id: 'preferences',
		icon: 'allianz_preferencesicon',
		parameters: {
			'showInAltNav': true
		},
		url: '',
		children: [
			{
				nameKey: 'subnav.preferences.subaccount.alt',
				id: 'persons',
				url: '/workflow.do?from=menu&to=persons'
			},
			{
				nameKey: 'digitaleVisitenkarte.pageTitle',
				id: 'digitaleVisitenkarte',
				url: 'digitale-visitenkarte',
				query: { resetBreadcrumbs: true },
			},
			{
				nameKey: 'kpi.dashboard.only.label',
				id: 'kpiDashboard',
				url: 'kpi-dashboard',
				query: { resetBreadcrumbs: true },
				permission: and(
					feature.has('showKpiDashboard'),
					not(
						feature.has('socialmedia')))
			},
			{
				nameKey: 'kpi.some-dashboard.label',
				id: 'socialMediaDashboard',
				url: 'social-media/social-media-dashboard',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						not(
							feature.has('showKpiDashboard')))
			},
			{
				nameKey: 'kpi.overview-dashboards.label',
				id: 'dashboardsOverview',
				url: 'kpi-dashboard/overview',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						and(
							feature.has('showKpiDashboard')))
			},
			{
				nameKey: 'text.select.prefs.abos',
				id: 'aboverwaltung',
				url: 'configuration/aboverwaltung',
				query: {resetBreadcrumbs: true},
				permission:
					and(feature.has('aboVerwaltung'))
			},

			{
				nameKey: 'subnav.preferences.addresses.alt',
				id: 'adresses',
				url: '/workflow.do?from=menu&to=adresses'
			},
			{
				nameKey: 'subnav.preferences.hra.alt',
				id: 'hra',
				url: '/workflow.do?from=menu&to=hra'
			},
			{
				nameKey: 'subnav.preferences.posterset.alt',
				id: 'posterset',
				url: '/workflow.do?from=menu&to=posterset',
				permission: feature.has('poster')
			},
			{
				nameKey: 'subnav.preferences.addressset.alt',
				id: 'addressset',
				url: '/workflow.do?from=menu&to=addressset'
			},
			{
				treeName: 'userpicture',
				name: 'userpictureTree',
				parameters: {
					'hideParent': true
				},
				url: '',
				id: 'userpictureTree'
			},
			{
				nameKey: 'subnav.vtconversiondocuments.alt',
				id: 'vtconversiondocuments',
				url: '/workflow.do?from=menu&to=slicelistviewconverted',
				permission: user.hasState('oldUserId')
			}
		]
	},
	{
		id: 'spacer',
	},
	{
		nameKey: 'subnav.preferences.admin.alt',
		id: 'admin',
		url: 'workflow.do?from=menu&to=admin',
		permission:
			or(
				not(
					or(
						user.isAttribute('accessGroup', 'NormalUser'),
						user.isAttribute('accessGroup', 'AV-Freigeber'),
						user.isAttribute('accessGroup', 'Allianz-NormalUser')
					)
				),
				and(user.has('service'), not(user.has('avFreigeber'))),
				user.any(['konfiguration', 'lektorat', 'showReport'])
			),
		children: [
			{
				nameKey: 'subnav.preferences.password.alt',
				id: 'password',
				url: '/workflow.do?from=menu&to=password',
				permission: not(user.has('avFreigeber'))
			},
			{
				nameKey: 'image.nav.service.alt',
				id: 'service',
				url: '/workflow.do?from=menu&to=service',
				permission: and(user.has('service'), feature.has('service'), not(user.has('avFreigeber')))
			},
			{
				nameKey: 'image.nav.konfiguration.alt',
				id: 'konfiguration',
				url: '/workflow.do?from=menu&to=konfiguration',
				permission: and(user.has('konfiguration'), feature.has('konfiguration'))
			},
			{
				treeName: 'webtm',
				name: 'webtmTree',
				url: '',
				id: 'webtmTree',
				permission: and(feature.has('webtm'), user.has('adminWebTmGrid')),
			},			{
				nameKey: 'text.lektorat',
				id: 'lektorat',
				url: '/workflow.do?from=menu&to=lektorat',
				permission: and(user.has('lektorat'), feature.has('lektorat'))
			},
			{
				treeName: 'report',
				name: 'reportTree',
				url: '',
				id: 'reportTree',
				permission: and(user.has('showReport'), feature.has('report'))
			}
		]
	},
	{
		nameKey: 'subnav.freigeben.alt',
		id: 'service-archive',
		url: '/workflow.do?from=menu&to=service-archive',
		permission: user.has('avFreigeber')
	}
];
